export const useContactUs = function (modal) {
  const { start, finish } = useLoadingIndicator();
  const rc = useRuntimeConfig();
  const toast = useToast();
  const { t, locale } = useI18n();
  const form = reactive({
    name: "",
    phone: "",

    nameError: false,
    phoneError: false,
  });
  async function sendForm() {
    form.nameError = !form.name.trim();
    form.phoneError = !form.phone.trim();

    if (form.nameError || form.phoneError) {
      return toast.add({
        title: t("fillForm"),
        color: "red",
      });
    }
    const body = {
      name: form.name,
      phone: form.phone,
    };
    try {
      start();
      await $fetch(rc.public.api + "/api/application/contact-us", {
        method: "POST",
        body,
      });
      toast.add({
        title: t("contactSuccess"),
      });

      resetForm();
      modal.value = false;
    } catch (error) {
      console.log(error);
      toast.add({
        title:
          error?.data?.context?.message ||
          error?.data?.message ||
          "Internal error",
        color: "red",
      });
    } finally {
      finish();
    }
  }
  function resetForm() {
    form.name = form.phone = "";
    form.nameError = form.phoneError = false;
  }

  return {
    form,
    sendForm,
  };
};
