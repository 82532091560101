<template>
    <div class="language-selector">
        <USelect v-model="lang" :options="options" :aria-label="t('language')" />
    </div>
</template>
<script setup>

const { locale, locales, t } = useI18n()
const router = useRouter()
const switchLocalePath = useSwitchLocalePath()
const lang = ref(locale.value);
const options = computed(() => locales.value.map(l => ({
    label: l.code.toUpperCase(),
    value: l.code
}))
)
watch(() => lang.value, (v) => {
    router.replace(switchLocalePath(v))
})

</script>