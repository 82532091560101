export const useMainStore = defineStore("main", () => {
  const rc = useRuntimeConfig();
  const state = reactive({
    home: null,
  });
  const inner = {
    promise: null,
  };
  async function getHomeData() {
    const res = await $fetch(rc.public.api + "/api/pages/home");
    state.home = res.data;
  }

  async function initStore() {
    if (!inner.promise) {
      inner.promise = Promise.all([getHomeData()]);
    }
    return inner.promise;
  }

  return {
    state,
    initStore,
    getHomeData,
  };
});
