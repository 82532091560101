import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as indexH4WMQ3g5UTMeta } from "/vercel/path0/pages/catalog/[type]/[id]/group/[group]/index.vue?macro=true";
import { default as _91product_930DGxwTZxh4Meta } from "/vercel/path0/pages/catalog/[type]/[id]/group/[group]/product/[product].vue?macro=true";
import { default as indexmVMwkO0GQIMeta } from "/vercel/path0/pages/catalog/[type]/[id]/index.vue?macro=true";
import { default as _91product_93t0sY24qITXMeta } from "/vercel/path0/pages/catalog/[type]/[id]/product/[product].vue?macro=true";
import { default as indexFMvn29UMPGMeta } from "/vercel/path0/pages/catalog/[type]/index.vue?macro=true";
import { default as contactsvmO9gwdyLiMeta } from "/vercel/path0/pages/contacts.vue?macro=true";
import { default as cooperationxtbb1UR4wQMeta } from "/vercel/path0/pages/cooperation.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_93M12rUi9E1dMeta } from "/vercel/path0/pages/news/[id].vue?macro=true";
import { default as indexxeWP9UFz4RMeta } from "/vercel/path0/pages/news/index.vue?macro=true";
import { default as vacancygVNW5IBcupMeta } from "/vercel/path0/pages/vacancy.vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about___ru",
    path: "/about",
    component: () => import("/vercel/path0/pages/about.vue")
  },
  {
    name: "about___uz",
    path: "/uz/about",
    component: () => import("/vercel/path0/pages/about.vue")
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/vercel/path0/pages/about.vue")
  },
  {
    name: "catalog-type-id-group-group___ru",
    path: "/catalog/:type()/:id()/group/:group()",
    component: () => import("/vercel/path0/pages/catalog/[type]/[id]/group/[group]/index.vue")
  },
  {
    name: "catalog-type-id-group-group___uz",
    path: "/uz/catalog/:type()/:id()/group/:group()",
    component: () => import("/vercel/path0/pages/catalog/[type]/[id]/group/[group]/index.vue")
  },
  {
    name: "catalog-type-id-group-group___en",
    path: "/en/catalog/:type()/:id()/group/:group()",
    component: () => import("/vercel/path0/pages/catalog/[type]/[id]/group/[group]/index.vue")
  },
  {
    name: "catalog-type-id-group-group-product-product___ru",
    path: "/catalog/:type()/:id()/group/:group()/product/:product()",
    component: () => import("/vercel/path0/pages/catalog/[type]/[id]/group/[group]/product/[product].vue")
  },
  {
    name: "catalog-type-id-group-group-product-product___uz",
    path: "/uz/catalog/:type()/:id()/group/:group()/product/:product()",
    component: () => import("/vercel/path0/pages/catalog/[type]/[id]/group/[group]/product/[product].vue")
  },
  {
    name: "catalog-type-id-group-group-product-product___en",
    path: "/en/catalog/:type()/:id()/group/:group()/product/:product()",
    component: () => import("/vercel/path0/pages/catalog/[type]/[id]/group/[group]/product/[product].vue")
  },
  {
    name: "catalog-type-id___ru",
    path: "/catalog/:type()/:id()",
    component: () => import("/vercel/path0/pages/catalog/[type]/[id]/index.vue")
  },
  {
    name: "catalog-type-id___uz",
    path: "/uz/catalog/:type()/:id()",
    component: () => import("/vercel/path0/pages/catalog/[type]/[id]/index.vue")
  },
  {
    name: "catalog-type-id___en",
    path: "/en/catalog/:type()/:id()",
    component: () => import("/vercel/path0/pages/catalog/[type]/[id]/index.vue")
  },
  {
    name: "catalog-type-id-product-product___ru",
    path: "/catalog/:type()/:id()/product/:product()",
    component: () => import("/vercel/path0/pages/catalog/[type]/[id]/product/[product].vue")
  },
  {
    name: "catalog-type-id-product-product___uz",
    path: "/uz/catalog/:type()/:id()/product/:product()",
    component: () => import("/vercel/path0/pages/catalog/[type]/[id]/product/[product].vue")
  },
  {
    name: "catalog-type-id-product-product___en",
    path: "/en/catalog/:type()/:id()/product/:product()",
    component: () => import("/vercel/path0/pages/catalog/[type]/[id]/product/[product].vue")
  },
  {
    name: "catalog-type___ru",
    path: "/catalog/:type()",
    component: () => import("/vercel/path0/pages/catalog/[type]/index.vue")
  },
  {
    name: "catalog-type___uz",
    path: "/uz/catalog/:type()",
    component: () => import("/vercel/path0/pages/catalog/[type]/index.vue")
  },
  {
    name: "catalog-type___en",
    path: "/en/catalog/:type()",
    component: () => import("/vercel/path0/pages/catalog/[type]/index.vue")
  },
  {
    name: "contacts___ru",
    path: "/contacts",
    component: () => import("/vercel/path0/pages/contacts.vue")
  },
  {
    name: "contacts___uz",
    path: "/uz/contacts",
    component: () => import("/vercel/path0/pages/contacts.vue")
  },
  {
    name: "contacts___en",
    path: "/en/contacts",
    component: () => import("/vercel/path0/pages/contacts.vue")
  },
  {
    name: "cooperation___ru",
    path: "/cooperation",
    component: () => import("/vercel/path0/pages/cooperation.vue")
  },
  {
    name: "cooperation___uz",
    path: "/uz/cooperation",
    component: () => import("/vercel/path0/pages/cooperation.vue")
  },
  {
    name: "cooperation___en",
    path: "/en/cooperation",
    component: () => import("/vercel/path0/pages/cooperation.vue")
  },
  {
    name: "faq___ru",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___uz",
    path: "/uz/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "index___ru",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___uz",
    path: "/uz",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "news-id___ru",
    path: "/news/:id()",
    component: () => import("/vercel/path0/pages/news/[id].vue")
  },
  {
    name: "news-id___uz",
    path: "/uz/news/:id()",
    component: () => import("/vercel/path0/pages/news/[id].vue")
  },
  {
    name: "news-id___en",
    path: "/en/news/:id()",
    component: () => import("/vercel/path0/pages/news/[id].vue")
  },
  {
    name: "news___ru",
    path: "/news",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "news___uz",
    path: "/uz/news",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "news___en",
    path: "/en/news",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "vacancy___ru",
    path: "/vacancy",
    component: () => import("/vercel/path0/pages/vacancy.vue")
  },
  {
    name: "vacancy___uz",
    path: "/uz/vacancy",
    component: () => import("/vercel/path0/pages/vacancy.vue")
  },
  {
    name: "vacancy___en",
    path: "/en/vacancy",
    component: () => import("/vercel/path0/pages/vacancy.vue")
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/ru-sitemap.xml",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/uz-sitemap.xml",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stub5X4Ei38PMg
  }
]