<template>
    <div ref="navbarRoot">
        <nav class="application-navbar pc">
            <div class="navbar-top">
                <NuxtLink :to="localePath('/')">
                    <img class="application-logo" src="/logo.png" alt="Brand Logo" width="136" height="52" />
                </NuxtLink>
                <div class="navbar-top-inner" :class="{ search: searchOpened }">
                    <div class="controls">
                        <div class="controls-inner">
                            <div class="search-wrapper" ref="searchWrapper">
                                <Icon name="iconamoon:search-light" style="color: black; " size="20px"
                                    @click="toggleSearch" />
                                <input type="text" class="search-input" placeholder="Что вам нужно найти?"
                                    v-model="searchQuery">
                            </div>
                            <Transition name="nav-menu">
                                <div class="menu-items" v-if="showNavMenu">
                                    <span @click.stop="isMenuOpen = !isMenuOpen" style="cursor: pointer">{{
                                        t('products')
                                    }}</span>
                                    <NuxtLink :to="localePath('/vacancy')">{{ t('vacancy') }}</NuxtLink>
                                    <!-- <NuxtLink :href="localePath('/news')">{{ t('newsAndPromos') }}</NuxtLink> -->
                                    <NuxtLink :to="localePath('cooperation')">{{ t('cooperation') }}</NuxtLink>
                                    <NuxtLink :to="localePath('/about')">{{ t('aboutUs') }}</NuxtLink>
                                    <NuxtLink :to="localePath('/contacts')">{{ t('contacts') }}</NuxtLink>
                                    <NuxtLink :to="localePath('/faq')">FAQ</NuxtLink>
                                    <a target="_blank" download="catalog.pdf"
                                        :href="state.home.home_sections.products.file">
                                        {{ t('downloadCatalog') }}
                                    </a>
                                </div>
                            </Transition>
                        </div>
                    </div>
                    <div class="social-icons" v-if="state.home">
                        <ApplicationHeaderLanguage />

                        <a class="social-icon" :href="entry.link" v-for="entry in state.home.contacts_section.socials"
                            :key="entry.link">
                            <img :src="entry.icon" alt="">
                        </a>
                        <button class="my-btn flex align-center justify-center" @click="goToContactForm">{{
                            t('contactUs') }}</button>
                    </div>
                </div>
            </div>
            <Transition name="navbar-bottom">
                <div class="navbar-bottom" v-if="searchOpened || isMenuOpen">
                    <Transition name="nav-menu" mode="out-in">
                        <!-- SEARCH -->
                        <div class="my-container search-container" v-if="searchOpened">
                            <div class="helper" v-if="searchResults.length === 0">{{ t('enterSearchQuery') }}</div>
                            <template v-else>
                                <NuxtLink
                                    :to="localePath(`/catalog/category/${prod.category.id}/group/${prod.group.id}/product/${prod.id}`)"
                                    class="product-link" v-for="prod in searchResults" :key="prod.id">
                                    <img :src="prod.image" :alt="prod.title[locale]">
                                    <span>
                                        {{ prod.title[locale] }}
                                    </span>
                                </NuxtLink>
                            </template>
                        </div>
                        <div class="my-container menu-container" v-else>
                            <div class="main-menu">
                                <NuxtLink :to="localePath('/catalog/category')">{{ t('allProducts') }}</NuxtLink>
                                <div class="flex justify-between gap-2" @mouseover="currentIndex = null">
                                    <span style="cursor: pointer">{{ t('ourBrands') }}</span>
                                    <Icon name="uiw:right" class="cursor-pointer"></Icon>
                                </div>
                                <NuxtLink :to="localePath(`/catalog/category/${cat.id}`)" v-for="cat in categories"
                                    @mouseover="currentIndex = cat.id">{{
                                        cat.name[locale] }}
                                </NuxtLink>
                            </div>
                            <div class="main-screen" id="product-menu">
                                <div class="title" v-if="currentIndex == null">
                                    {{ t('ourBrands') }}
                                </div>
                                <div class="title" v-else>
                                    {{ catName }}
                                </div>
                                <div class="wrapper" v-if="currentIndex == null">
                                    <NuxtLink v-for="brand in state.home.brands" class="brand"
                                        :to="localePath(`/catalog/brand/${brand.id}`)">
                                        <img :src="brand.image" :alt="brand.name[locale]" />
                                    </NuxtLink>
                                </div>
                                <div class="flex flex-col gap-4" v-else style="max-height: 400px; overflow-y: auto;">
                                    <NuxtLink v-for="group in productList" style="cursor:pointer"
                                        :to="localePath(group.type === 'group' ? `/catalog/category/${currentIndex}/group/${group.id}` : `/catalog/category/${currentIndex}/product/${group.id}`)"
                                        class="product-link">
                                        {{ group.title[locale] }}
                                    </NuxtLink>
                                </div>

                            </div>
                        </div>
                    </Transition>
                </div>
            </Transition>
        </nav>
        <nav class="application-navbar-m mobile">
            <Transition name="slide-down" appear>
                <div class="bottom-wrapper" v-if="searchOpened || isMenuOpen">
                    <div class="search-container" v-if="searchOpened">
                        <div v-if="searchResults.length === 0" class="help">{{ t('enterSearchQuery') }}</div>
                        <template v-else>
                            <NuxtLink
                                :to="localePath(`/catalog/category/${entry.category.id}/group/${entry.group.id}/product/${entry.id}`)"
                                v-for="entry in searchResults" :key="entry.id" class="search-result">
                                <img :src="entry.image" />
                                <div> {{ entry.title[locale] }}</div>
                            </NuxtLink>
                        </template>
                    </div>
                    <div class="nav-container" v-else>
                        <Transition name="nav-menu" mode="out-in">
                            <div class="main-menu" v-if="menuState === 'main'">
                                <ul class="menu-items">
                                    <li @click="menuState = 'products'">
                                        <span>{{ t('products') }}</span>
                                    </li>
                                    <li>
                                        <NuxtLink :to="localePath('/vacancy')">{{ t('vacancy') }}</NuxtLink>
                                    </li>
                                    <li>
                                        <NuxtLink :to="localePath('/news')">{{ t('newsAndPromos') }}</NuxtLink>
                                    </li>
                                    <li>
                                        <NuxtLink :to="localePath('cooperation')">{{ t('cooperation') }}</NuxtLink>
                                    </li>
                                    <li>
                                        <NuxtLink :to="localePath('/about')">{{ t('aboutUs') }}</NuxtLink>
                                    </li>
                                    <li>

                                        <NuxtLink :to="localePath('/contacts')">{{ t('contacts') }}</NuxtLink>
                                    </li>
                                    <li>
                                        <NuxtLink :to="localePath('/faq')">FAQ</NuxtLink>
                                    </li>
                                </ul>
                                <ApplicationHeaderLanguage style="width: fit-content; margin-bottom: 24px;" />
                                <button class="my-btn" @click="goToContactForm">{{ t('contactUs') }}</button>
                                <div class="social-icons" v-if="state.home">
                                    <a :href="entry.link" v-for="entry in state.home.contacts_section.socials"
                                        :key="entry.link">
                                        <img :src="entry.icon" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="main-menu" v-else-if="menuState === 'products'">
                                <div class="menu-title" @click="menuState = 'main'">
                                    <Icon name="weui:back-filled" size="24" />
                                    {{ t('products') }}
                                </div>
                                <ul class="menu-items">
                                    <li>
                                        <NuxtLink :to="localePath('/catalog/category')">{{ t('allProducts') }}
                                        </NuxtLink>
                                    </li>
                                    <li @click="menuState = 'brand'" class="flex align-center">
                                        <span>{{ t('ourBrands') }}</span>
                                        <Icon name="uiw:right" class="cursor-pointer" style="margin-left: 20px;"></Icon>
                                    </li>
                                    <li v-for="cat in categories" :key="cat.id">
                                        <NuxtLink :to="localePath(`/catalog/category/${cat.id}`)">
                                            {{ cat.name[locale] }}
                                        </NuxtLink>
                                        <Icon name="uiw:right" class="cursor-pointer" style="margin-left: 20px;"
                                            @click="() => (menuState = 'brand', currentIndex = cat.id)">
                                        </Icon>
                                    </li>
                                </ul>
                            </div>
                            <div class="main-menu " v-else-if="menuState === 'brand'">
                                <div class="menu-title" @click="menuState = 'products'" v-if="currentIndex == null">
                                    <Icon name="weui:back-filled" size="24" />
                                    {{ t('ourBrands') }}
                                </div>
                                <div class="menu-title" @click="() => (menuState = 'products', currentIndex = null)"
                                    v-else>
                                    <Icon name="weui:back-filled" size="24" />
                                    {{ catName }}
                                </div>
                                <div class="grid grid-cols-2 gap-4 " v-if="currentIndex == null">
                                    <NuxtLink v-for="brand in state.home.brands" class="brand-card"
                                        :to="localePath(`/catalog/brand/${brand.id}`)">
                                        <img :src="brand.image" :alt="brand.name[locale]" />
                                    </NuxtLink>
                                </div>
                                <div class="flex flex-col gap-4" v-else>
                                    <NuxtLink v-for="group in productList" style="cursor:pointer"
                                        :to="localePath(group.type === 'group' ? `/catalog/category/${currentIndex}/group/${group.id}` : `/catalog/category/${currentIndex}/product/${group.id}`)"
                                        class="mt-4">
                                        {{ group.title[locale] }}
                                    </NuxtLink>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </Transition>
            <NuxtLink :to="localePath('/')" v-if="showNavMenu">
                <img class="application-logo" src="/logo.png" alt="Brand Logo" width="136" height="52" />
            </NuxtLink>
            <Icon name="weui:arrow-outlined" style="color: black; transform: rotate(180deg); cursor:pointer "
                size="20px" @click="toggleSearch" v-else />


            <div class="search-wrapper" :class="{ search: searchOpened }">
                <div class="search-wrapper-inner">
                    <input type="text" placeholder="Что вам нужно найти?" v-model="searchQuery" />
                    <Icon name="iconamoon:search-light" style="color: black; " size="20px" @click="toggleSearch" />
                </div>
            </div>
            <button class="hamburger" :class="{ open: isMenuOpen }"
                @click="() => (isMenuOpen = !isMenuOpen, currentIndex = null, menuState = 'main')"
                v-if="!searchOpened"></button>

        </nav>
    </div>
    <UModal v-model="contactModal" class="product-review_modal" :ui="{
        width: 'w-full ',
        overlay: {
            background: 'my-modal-overlay',
        },
    }">
        <form class="p-8 flex flex-col gap-8" @submit.prevent="sendForm">
            <div class="flex w-full justify-between align-center">
                <div class="modal-header">{{ t('contactUs2') }}</div>
                <div style="transform: rotateZ(45deg); cursor: pointer" @click="contactModal = false">
                    <Icon name="ph:plus" size="24" />
                </div>
            </div>
            <div class="grid grid-cols-2 gap-4">
                <input class="base-input secondary w-full" :placeholder="t('yourName')" type="text" v-model="form.name"
                    :class="{ error: form.nameError }" />
                <input class="base-input secondary w-full" :placeholder="t('yourPhone')" type="tel" v-model="form.phone"
                    :class="{ error: form.phoneError }" />
            </div>
            <button class="my-btn w-full flex align-center justify-center" style="width: 100%">{{ t('send') }}</button>

        </form>
    </UModal>
</template>

<script setup>
const { locale, t } = useI18n()
const localePath = useLocalePath();
const { beforeEach } = useRouter();
import { onClickOutside } from '@vueuse/core'
import { useDebounceFn } from '@vueuse/core'

const rc = useRuntimeConfig()
const { state, initStore } = useMainStore();
await initStore();
const categories = computed(() => state.home.categories.filter(el => el.show_on_menu))
const searchQuery = ref()
const searchResults = ref([])
watch(() => searchQuery.value,
    useDebounceFn(async (v) => {
        if (v && v.length > 3) {
            const res = await $fetch(rc.public.api + '/api/product', {
                query: {
                    search: v,
                    page: 1,
                    limit: 12,
                }
            })
            searchResults.value = res.data.list
        }
    }, 500)
)

const isMenuOpen = ref(false);
const navbarRoot = ref()
const searchWrapper = ref()
onClickOutside(navbarRoot, () => {
    if (isMenuOpen.value) {
        isMenuOpen.value = false
    }
    closeSearch()
})
onClickOutside(searchWrapper, () => {
    if (searchOpened.value && window.innerWidth > 1100) {

        closeSearch()
    }
    if (isMenuOpen.value && window.innerWidth > 1100) {
        isMenuOpen.value = false
    }
})

const showNavMenu = ref(true);
const searchOpened = ref(false);

function openSearch() {
    showNavMenu.value = false;
    setTimeout(() => {
        searchOpened.value = true
    }, 0)
}

function closeSearch() {
    searchOpened.value = false;
    setTimeout(() => {
        showNavMenu.value = true
    }, 300)
    searchQuery.value = '';
    searchResults.value = []
}

function toggleSearch() {
    searchOpened.value ? closeSearch() : openSearch()
}

function goToContactForm() {
    isMenuOpen.value = false;
    closeSearch()
    contactModal.value = true
}

const menuState = ref('main')

const currentIndex = ref(null);
const productList = ref([])
watch(() => currentIndex.value, async (v) => {
    if (!v) return;
    productList.value = (await $fetch(rc.public.api + '/api/product/products-and-groups', {
        query: {
            'filters[category_id]': v
        }
    })).data.list
})
const catName = computed(() => {
    if (!currentIndex.value) return '';
    return categories.value.find(el => el.id === currentIndex.value).name[locale.value]
})

beforeEach(() => {
    isMenuOpen.value = false;
    closeSearch()
    return true
})

const contactModal = ref(false)
const { form, sendForm } = useContactUs(contactModal)
</script>