<template>
    <input :type :placeholder v-model="model" class="base-input" :class="variant" />
</template>

<script setup>
const model = defineModel()
const emits = defineEmits(['update:modelValue'])

const props = defineProps({
    placeholder: String,
    type: String,
    variant: String
})
</script>