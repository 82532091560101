<template>
  <ApplicationLayout>
    <NuxtLoadingIndicator />
    <NuxtRouteAnnouncer />
    <NuxtPage />
  </ApplicationLayout>
</template>
<script setup>
const { locale } = useI18n()
useHead({
  htmlAttrs: {
    lang: locale.value,
  }
})
const { $colorMode } = useNuxtApp();
$colorMode.preference = 'light';
const { start, finish } = useLoadingIndicator();
</script>
<style lang="scss">
/* FONTS */
/* Reusable font-face definitions */
@font-face {
  font-family: 'Involve';
  src: url('/fonts/Involve-Regular.woff') format('woff'),
    url('/fonts/Involve-Regular.otf') format('opentype'),
    url('/fonts/Involve-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Involve';
  src: url('/fonts/Involve-Bold.woff') format('woff'),
    url('/fonts/Involve-Bold.otf') format('opentype'),
    url('/fonts/Involve-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Involve';
  src: url('/fonts/Involve-Medium.woff') format('woff'),
    url('/fonts/Involve-Medium.otf') format('opentype'),
    url('/fonts/Involve-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Involve';
  src: url('/fonts/Involve-SemiBold.woff') format('woff'),
    url('/fonts/Involve-SemiBold.otf') format('opentype'),
    url('/fonts/Involve-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Involve';
  src: url('/fonts/Involve-Oblique.woff') format('woff'),
    url('/fonts/Involve-Oblique.otf') format('opentype'),
    url('/fonts/Involve-Oblique.ttf') format('truetype');
  font-weight: normal;
  font-style: oblique;
}

@font-face {
  font-family: 'Involve';
  src: url('/fonts/Involve-BoldOblique.woff') format('woff'),
    url('/fonts/Involve-BoldOblique.otf') format('opentype'),
    url('/fonts/Involve-BoldOblique.ttf') format('truetype');
  font-weight: bold;
  font-style: oblique;
}

@font-face {
  font-family: 'Involve';
  src: url('/fonts/Involve-MediumOblique.woff') format('woff'),
    url('/fonts/Involve-MediumOblique.otf') format('opentype'),
    url('/fonts/Involve-MediumOblique.ttf') format('truetype');
  font-weight: 500;
  font-style: oblique;
}

@font-face {
  font-family: 'Involve';
  src: url('/fonts/Involve-SemiBoldOblique.woff') format('woff'),
    url('/fonts/Involve-SemiBoldOblique.otf') format('opentype'),
    url('/fonts/Involve-SemiBoldOblique.ttf') format('truetype');
  font-weight: 600;
  font-style: oblique;
}

/* FONTS */
/* NULLIFY CSS */
html {
  scroll-behavior: smooth;
  overflow-y: scroll;
  font-family: Involve;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
}

footer,
header,
nav,
section,
main {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

@media (max-width: 1100px) {
  .pc {
    display: none;
  }
}

@media (min-width: 1101px) {
  .mobile {
    display: none;
  }
}

.application-logo {
  height: 52px;
}

@media (max-width: 1539px) {
  .application-logo {
    height: 40px;
    width: 105px;
  }
}

@media (max-width: 1100px) {
  .application-logo {
    height: 36px;
    width: 96px;
  }
}

.application-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &>main {
    flex-grow: 1;
  }
}

.my-container {
  max-width: 1294px;
  margin: 0 auto;
}

@media (max-width: 1334px) {
  .my-container {
    margin: 0 20px;
  }
}

.typo-body-m {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.world-map {
  width: 100%;
  aspect-ratio: 2/1;

  & .marker {
    cursor: pointer;

    &:hover,
    &:has(+*:hover) {
      fill: var(--accent-hover);
    }

    &+*:hover {
      cursor: pointer;
    }

  }

  // & path[id]:not([id="uzbekistan"]):hover {
  //   fill: #DBC4E3;
  // }
  & path[data-hover]:hover {
    fill: #DBC4E3;
  }

  & *[data-country] {
    cursor: pointer;
  }

  & * {
    transform-box: fill-box;
    transform-origin: center;
  }

  &+.map-info {
    position: absolute;
    background-color: var(--primary-inverse);
    border-radius: 12px;
    padding: 16px;
    padding-right: 20px;
    border: 1px solid var(--disabled-text);
    max-width: 450px;

    & .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      margin-left: 40px;
      margin-bottom: 40px;
      margin-right: 40px;
    }

    & .description {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      opacity: 0.7;

    }

    & .icon {
      position: absolute;
      top: 16px;
      left: 16px;
    }

    & .close {
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
    }
  }
}



/* Colors*/
:root {

  --primary: #201E1E;
  --secondary-dark: #2F2C2C;
  --accent: #861A50;
  --accent-hover: #67143D;
  --disabled-text: #717171;
  --disabled: #B9BCC5;

  --secondary: #EDE8F2;
  --tertiary: #F8F5FA;
  --primary-inverse: #FFFFFF;
}

body {
  background-color: #F8F5FA;
}

@media (max-width: 1100px) {
  html:has(.bottom-wrapper) {
    overflow: hidden !important;
  }
}

.error {
  border-color: #F45725 !important;

}

html {
  color: var(--primary)
}

.header {
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: -0.02em;
  margin-bottom: 40px;
}

.page {
  margin-top: 150px;
  margin-bottom: 160px;
}

@media (max-width: 1100px) {
  .header {
    font-size: 32px;
    line-height: 40px;
  }

  .page {
    margin-top: 136px;
    margin-bottom: 80px;
  }
}



.span2 {
  grid-column: span 2;
}

.router-link-active {
  color: var(--accent);
}


/* Button*/
.my-btn {
  border: none;
  outline: none;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--primary-inverse);
  border-radius: 50px;
  background-color: var(--accent);
  width: fit-content;

  &:hover {
    background-color: var(--accent-hover);
  }

  &:disabled {
    color: var(--disabled-text);
    background-color: var(--disabled);
  }

  &.secondary {
    border: 1px solid var(--secondary);
    background-color: transparent;

    &:hover {
      background-color: var(--accent);
      border-color: var(--accent);
    }

    &:disabled {
      color: var(--disabled-text);
      background-color: var(--disabled);
    }
  }

  &.third {
    border: 1px solid var(--primary);
    background-color: transparent;

    color: var(--primary);

    &:hover {
      background-color: var(--accent);
      border-color: var(--accent);
      color: var(--primary-inverse);
    }

    // &:disabled {
    //   color: var(--disabled-text);
    //   background-color: var(--disabled);
    // }
  }

}

/*------------*/
/* TRANSITIONS */
/*------------*/
.nav-menu-enter-active,
.nav-menu-leave-active {
  transition: opacity 0.5s ease;
}

.nav-menu-enter-from,
.nav-menu-leave-to {
  opacity: 0;
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.3s ease;
}

.slide-down-enter-from,
.slide-down-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}

.navbar-bottom-enter-active,
.navbar-bottom-leave-active {
  transition: max-height 0.2s linear;
}

.navbar-bottom-enter-from,
.navbar-bottom-leave-to {
  max-height: 0;
}

.navbar-bottom-enter-to,
.navbar-bottom-leave-from {
  max-height: 500px;
}

.catalog-enter-active {
  transition: all 0.5s ease;
  transition-delay: var(--delay);
}

.catalog-enter-from {
  opacity: 0;
  transform: translateY(-50%);
}

.catalog-enter-to {
  opacity: 1;
  transform: translateY(0);
}

/*------------*/
/* COMPONENTS */
/*------------*/

/* product review modal*/
.my-modal-overlay {
  background-color: #201E1E66 !important;
}

.product-review_modal {

  & .modal-header {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }

  & .text {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    opacity: 0.6;
  }

}

/* product-card*/
.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .img-wrapper {
    aspect-ratio: 1/1;
    border-radius: 12px;
    background-color: var(--secondary);
    margin-bottom: 12px;
    transition: all 0.2s linear;
  }

  & img {
    aspect-ratio: 1/1;
    width: 100%;
    padding: 50px;
    background-color: transparent;
    object-fit: contain;
    max-width: 100%;
    transition: all 0.2s linear;
  }

  & p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  }

  &:hover {
    & .img-wrapper {
      background-color: var(--disabled);
    }

    & img {
      transform: scale(1.1);
    }

    & p {
      color: var(--accent)
    }
  }

}

/* news-card*/
.news-card {
  border-radius: 12px;
  background-color: var(--secondary);
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;

  & img {
    border-radius: 12px;
    aspect-ratio: 306/171;
    margin-bottom: 20px;
    object-fit: cover;

  }

  & .info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    margin-bottom: 12px;
    padding: 0 16px;
    color: var(--primary);
    opacity: 0.4;
  }

  & .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
    padding: 0 16px;
    margin-bottom: 16px;

  }

  & .content {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    padding: 0 16px;
    margin-bottom: 40px;
    color: var(--primary);
    opacity: 0.7;
  }

  &>a {
    display: block;
    margin-bottom: 16px;
    margin-left: 16px;
  }
}

@media (max-width: 1100px) {
  .news-card {
    &>a {
      margin-right: 16px;
    }
  }
}

/* chip-select*/
.chip-select {
  margin: 0 auto 40px;
  display: flex;
  gap: 12px;

  width: fit-content;
  max-width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &.left {
    margin-left: 0;
  }

  & .entry {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    width: fit-content;
    white-space: nowrap;


    padding: 12px;
    border-radius: 30px;
    border: 1px solid var(--accent);
    color: var(--accent);

    &.active {
      background: var(--accent);
      color: var(--primary-inverse);
      cursor: unset;
    }
  }
}

@media (max-width:1100px) {
  .chip-select {
    margin-left: 0;
    margin-right: -20px;
    max-width: calc(100% + 20px);
  }
}


/* base-input*/
.base-input {
  border: 1px solid var(--secondary-dark);
  padding: 14px 0 14px 20px;
  border-radius: 12px;
  outline: none;
  background-color: var(--secondary-dark);

  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--primary-inverse);


  &::placeholder {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--primary-inverse);
    opacity: 0.6;
  }

  &.light {
    border-color: var(--primary-inverse);
    background-color: var(--primary-inverse);
    color: var(--primary);

    &::placeholder {
      color: var(--primary);
    }
  }

  &.secondary {
    border-color: var(--tertiary);
    background-color: var(--tertiary);
    color: var(--primary);

    &::placeholder {
      color: #201E1E;
    }
  }

  &.error {
    border-color: #F45725;
  }
}

/* application-catalog*/
@media(min-width: 1101px) {
  .application-catalog {

    & h2 {
      text-align: center;
    }

    & .products {
      position: relative;

      display: grid;
      grid-auto-flow: column;
      grid-template-rows: 1fr 1fr;
      gap: 2%;
      row-gap: 40px;
      min-height: 600px;
      max-height: 630px;
      grid-auto-columns: 15%;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      grid-auto-flow: row;
      grid-template-columns: repeat(6, 1fr);

      &::-webkit-scrollbar {
        display: none;
      }

      & .item {
        & .image-wrapper {
          background-color: var(--secondary);
          width: 100%;
          aspect-ratio: 85/100;
          padding: 25px;
          border-radius: 12px;
          overflow: hidden;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.2s linear;

          &:hover {
            background-color: var(--disabled);
          }

        }

        & img {
          max-width: 100%;
          max-height: 100%;
          object-position: center;
          object-fit: contain;
          transition: all 0.2s linear;

        }

        &:hover img {
          transform: scale(1.1)
        }

        & div {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          text-align: center;

        }

        &.final {
          flex-basis: 100%;
        }
      }

    }
  }
}

@media (max-width: 1100px) {
  .application-catalog {
    & h2 {
      text-align: center;
    }

    & .products {
      display: flex;
      margin-right: -20px;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      gap: 12px;

      &::-webkit-scrollbar {
        display: none;
      }

      & .item {
        width: 45%;
        flex-shrink: 0;

        & img {
          background-color: var(--secondary);
          width: 100%;
          aspect-ratio: 85/100;
          padding: 25px;
          border-radius: 12px;
          object-position: center;
          object-fit: contain;
          margin-bottom: 10px;
        }

        & div {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          text-align: center;

        }

        &.final {
          flex-basis: 100%;
        }
      }

    }
  }
}

/* ProductSeries*/
.product-series {
  & .entry {
    border-radius: 12px;
    position: relative;
    border: 1px solid var(--secondary);
    aspect-ratio: 2.5/1;
    overflow: hidden;

    & .overlay {
      position: absolute;
      inset: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;
      padding-bottom: 40px;
      transition: all 0.2s linear;

      &:hover {
        backdrop-filter: brightness(70%);
      }
    }

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}

@media (max-width: 1100px) {
  .product-series {
    & .entry {
      aspect-ratio: 2/1;

      & .overlay {
        padding-bottom: 16px;
      }

      padding-bottom: 16px;
    }
  }
}

/* NAVBAR*/
.application-navbar {
  padding: 20.5px 70px;
  background-color: #F8F5FA30;
  backdrop-filter: blur(50px);
  position: fixed;
  width: 100%;
  z-index: 10;

  top: 0;

  & .navbar-top {
    display: grid;
    grid-template-columns: 136px 1fr;
    gap: 12%;
    align-items: center;
  }

  & .navbar-top-inner {
    display: flex;
    width: 100%;
    gap: 1%;

    & .menu-items {
      display: flex;
      gap: 24px;
      transition: all 0.3s linear;
      overflow: hidden;

      & a {
        white-space: nowrap;


      }

      & a,
      &>span {
        &:hover {
          color: var(--accent)
        }
      }

      & * {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;

      }
    }

    & .search-wrapper {
      padding: 16px;
      display: flex;
      gap: 16px;
      transition: all 0.3s linear;
      background-color: transparent;
      border-radius: 40px;
      width: 40px;
      overflow: hidden;

      & span {
        flex-shrink: 0;
        cursor: pointer;
      }
    }

    &.search .search-wrapper {
      background-color: var(--secondary);
      width: 100%;
      aspect-ratio: unset;
    }

    &.search .menu-items {
      width: 0px;
      opacity: 0;
    }



    & .search-input {
      flex-grow: 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      background-color: transparent;
      visibility: hidden;
      outline: none;
    }

    &.search .search-input {
      flex-grow: 1;
      visibility: visible;

    }
  }

  & .controls {
    flex-grow: 1;

    & .controls-inner {
      width: 100%;
      overflow: hidden;
      display: flex;
      gap: 124px;
      align-items: center;
    }
  }

  & .social-icons {
    & .my-btn {
      min-width: 180px;
    }

    display: flex;
    align-items: center;
    gap: 8px;

    & .social-icon {
      background-color: #fff;
      padding: 16px;
      border-radius: 100%;

      &>span {
        display: block;
      }
    }
  }

  & .language-selector {
    & select {
      --tw-ring-shadow: none;
      background-color: transparent;
    }

    & span {
      color: black;
    }
  }

  & .navbar-bottom {
    background-color: var(--primary-inverse);
    top: 100%;
    width: 100%;
    left: 0;
    position: absolute;
    overflow-y: hidden;
    transition: all 0.2s linear;

    & .menu-container {
      margin-top: 40px;
      margin-bottom: 80px;
      display: grid;
      grid-template-columns: 200px 1fr;
      gap: 60px;
    }

    & .main-menu {
      display: flex;
      width: 100%;
      gap: 20px;
      flex-direction: column;

      & :hover {
        color: var(--accent)
      }

      &>* {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;

      }
    }

    & .main-screen {
      & .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 24px;
      }

      & .product-link {
        cursor: pointer;

        &:hover {
          color: var(--accent)
        }
      }

      & .wrapper {
        display: grid;
        max-width: 100%;
        gap: 24px;
        row-gap: 24px;
        grid-template-columns: repeat(6, 1fr);
        overflow-y: auto;
        max-height: 400px;

        & .brand {
          border: 1px solid #201E1E1F;
          border-radius: 8px;
          overflow: hidden;
          padding-left: 30px;
          padding-right: 30px;
          display: flex;
          align-items: center;
          width: 100%;
          aspect-ratio: 1/1;
          transition: all 0.2s linear;

          &:hover {
            box-shadow: inset 0px 0px 4px var(--accent);
          }

        }
      }
    }

    & .search-container {
      margin-top: 40px;
      margin-bottom: 80px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
      height: 400px;
      overflow: auto;

      & .helper {
        grid-column: span 6;
        text-align: center;
      }

      & .product-link {
        display: flex;
        align-items: center;
        gap: 40px;
        padding: 5px;
        border-radius: 12px;

        border: 1px solid var(--disabled-text);

        & img {
          height: 80px;
          aspect-ratio: 1/1;
          object-fit: contain;
        }
      }
    }
  }
}

@media (max-width: 1820px) {
  .application-navbar {

    & .navbar-top {
      gap: 8%;
    }

    & .navbar-top-inner {
      gap: 10px;

      & .menu-items {
        gap: 22px;

      }
    }

    & .controls {
      & .controls-inner {
        gap: 100px;
      }
    }
  }
}

@media (max-width: 1700px) {
  .application-navbar {

    & .navbar-top {
      gap: 5%;
    }

    & .navbar-top-inner {
      gap: 10px;

      & .menu-items {
        gap: 16px;
      }
    }

    & .controls {
      & .controls-inner {
        gap: 6%;
      }
    }
  }
}

@media (max-width: 1600px) {
  .application-navbar {

    & .navbar-top {
      gap: 4%;
    }

    & .navbar-top-inner {
      gap: 6px;

      & .menu-items {
        gap: 16px;
      }
    }

    & .controls {
      & .controls-inner {
        gap: 4%;
      }
    }
  }
}

@media (max-width: 1539px) {
  .application-navbar {
    padding: 20.5px 30px;

    & .navbar-top {
      gap: 3%;
    }

    & .navbar-top-inner {
      gap: 10px;

      & .menu-items {
        gap: 15px;
      }

      & .menu-items {
        & * {
          font-size: 14px;
        }
      }

      & .search-wrapper {
        & span {
          flex-shrink: 0;
          cursor: pointer;
        }
      }

      & .search-input {
        visibility: hidden;
      }

      &.search .search-input {
        flex-grow: 1;
        visibility: visible;
      }
    }

    & .controls {
      flex-grow: 1;

      & .controls-inner {
        width: 100%;
        overflow: hidden;
        display: flex;
        gap: 3%;
        align-items: center;
      }
    }


    & .social-icons {
      & .my-btn {
        padding: 12px;
        min-width: 164px;
      }

      gap: 5px;

      & .social-icon {
        padding: 12px;
      }
    }
  }
}

@media (max-width: 1370px) {
  .application-navbar {

    & .navbar-top {
      gap: 0;
    }

    & .navbar-top-inner {
      gap: 0;

      & .menu-items {
        gap: 14px;
      }
    }

    & .controls {
      & .controls-inner {
        gap: 10px;
      }
    }
  }
}

@media (max-width: 1299px) {
  .application-navbar {
    padding: 20px;

    & .navbar-top {
      gap: 5px
    }

    & .navbar-top-inner {
      gap: 5px;

      & .menu-items {
        & * {
          font-size: 14px;
        }
      }
    }

    & .controls {
      & .controls-inner {
        gap: 1%;
      }
    }


    & .social-icons {
      gap: 2px;

      & .social-icon {
        padding: 8px;
      }
    }
  }
}

@media (max-width: 1175px) {
  .application-navbar {

    & .navbar-top {
      gap: 0;
    }

    & .navbar-top-inner {
      gap: 0;

      & .menu-items {
        gap: 8px;
      }
    }

    & .controls {
      & .controls-inner {
        gap: 10px;
      }
    }
  }
}

@media (max-width: 1140px) {
  .application-navbar {

    & .navbar-top {
      grid-template-columns: 107px 1fr;
    }
  }
}

@media (max-width: 1100px) {
  .application-navbar-m {
    background-color: #F8F5FA30;
    backdrop-filter: blur(50px);
    width: 100vw;
    z-index: 10;
    position: fixed;
    top: 0;
    height: 76px;
    display: flex;
    align-items: center;
    gap: 16px;
    padding-right: 20px;
    padding-left: 20px;


    .search-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: row-reverse;
    }

    & .search-wrapper-inner {
      padding: 10px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 10px;
      width: 40px;
      overflow: hidden;
      transition: all 0.3s linear;

      &>span {
        flex-shrink: 0;
      }

      & input {
        background-color: transparent;
        outline: none;
      }
    }

    & .search .search-wrapper-inner {
      width: 100%;
      padding: 16px;
      background-color: var(--secondary);

      & input {
        flex-grow: 1;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;

        &::placeholder {
          color: var(--primary);
          opacity: 0.5;

        }
      }
    }

    & .hamburger {
      width: 32px;
      height: 32px;
      margin-left: 20px;
      position: relative;


      &::before {
        position: absolute;
        content: '';
        width: 22px;
        height: 2px;
        background-color: var(--primary);
        left: 5px;
        top: 12px;
        transition: all 0.2s linear;
      }

      &::after {
        transition: all 0.2s linear;
        position: absolute;
        content: '';
        height: 2px;
        position: absolute;
        content: '';
        width: 18px;
        height: 2px;
        background-color: var(--primary);
        left: 9px;
        top: 20px;
      }

      &.open::before {
        transform: translateY(3px) rotate(45deg);
      }

      &.open::after {
        transform: translateY(-5px) translateX(-4px) rotate(-45deg);
        width: 22px;
      }

      &:hover::after,
      &:hover::before {
        box-shadow: 0px 0px 3px var(--accent);
      }


    }

    & .bottom-wrapper {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100vw;
      height: calc(100vh - 100%);
      padding: 24px 20px 40px;

      background-color: var(--primary-inverse);

      & .nav-container {
        overflow-y: scroll;
        height: 100%;
      }

      & .menu-items li {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 32px;

        &:not(:last-child) {
          margin-bottom: 18px;
        }
      }

      & .social-icons {
        display: flex;
        align-items: center;
        margin-top: 40px;
        gap: 12px;

        & a {
          padding: 16px;
          border-radius: 40px;
          background-color: var(--secondary);

          & img {
            width: 20px;
            aspect-ratio: 1/1;
          }
        }
      }
    }

    & .search-container {
      & .help {
        padding-top: 40px;
        text-align: center;
      }

      & .search-result {
        display: flex;
        gap: 20px;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 12px;
        }

        & img {
          height: 100px;
          aspect-ratio: 1/1;
          object-fit: contain;
        }
      }
    }

    & .menu-title {
      padding-bottom: 10px;
      border-bottom: 2px solid var(--disabled-text);
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      gap: 20px;
    }

    & .brand-card {
      border-radius: 12px;

      border: 1px solid #201E1E1F;
      padding: 20px;
      overflow: hidden;

      & img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: contain;
      }
    }
  }
}


/* FOOTER*/
.application-footer {
  background-color: var(--primary);
  padding-top: 70px;
  padding-bottom: 70px;

  & .divider {
    margin-top: 32px;
    margin-bottom: 32px;
    height: 2px;
    background-color: var(--secondary-dark);
  }

  & .footer-top {
    display: grid;
    grid-template-columns: 1fr 1fr;

    & .footer-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: start;
    }

    & .footer-tagline {
      font-size: 32px;
      font-weight: 500;
      line-height: 40px;
      letter-spacing: -0.02em;
      text-align: left;
      color: var(--primary-inverse);
      max-width: 430px;
    }

    & .footer-logo {
      height: 52px;
    }

    & .contact-form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      max-width: 525px;


      & .footer-form_header {
        grid-column: span 2;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        text-align: left;
        color: var(--primary-inverse);

      }

      & .my-btn {
        grid-column: span 2;
        width: 100%;
        text-align: center;
      }
    }



  }

  & .footer-bottom {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    & .footer-column {
      & .section-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: var(--primary-inverse);
        opacity: 0.6;
        margin-bottom: 16px;
      }

      & li {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: var(--primary-inverse);

        &:hover {
          color: var(--accent)
        }

        &.no-hover {
          color: var(--primary-inverse);
        }

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }

      &:last-child {
        display: flex;
        gap: 8px;
        align-self: start;

        & .social-icon {
          padding: 16px;
          border-radius: 100%;
          background-color: var(--primary-inverse);

          &>img {
            display: block;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }


}

@media (max-width: 1100px) {
  .application-footer {
    padding-top: 40px;
    padding-bottom: 40px;


    & .footer-top {
      display: block;

      & .footer-tagline {
        max-width: unset;
        margin-top: 24px;
        margin-bottom: 40px;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.02em;
      }

      & .footer-logo {
        height: 52px;
      }

      & .contact-form {
        max-width: unset;

        & .footer-form_header {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
        }
      }

    }

    & .footer-bottom {
      grid-template-columns: 1fr 1fr;
      row-gap: 40px;


      & .footer-column {
        & .section-title {
          font-size: 12px;
          line-height: 16px;
        }

        & li {
          font-size: 12px;
          line-height: 16px;

        }

        &:nth-child(3) {
          grid-column: span 2;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .application-footer {
    & .footer-top {
      & .contact-form {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

/* Home brands*/
.home-brands {
  & h2 {
    text-align: center;
  }

  & .brand-wrapper {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  & .overlay {
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #201E1ECD;
  }

  & #marquee-slider-loop {
    width: 100%;
  }

  & img {
    height: 70px;
    object-fit: contain;
  }

  & .img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 280px;
    aspect-ratio: 1/1;
  }


}

@media (max-width: 1100px) {
  .home-brands {
    & .overlay {
      display: flex;
      align-items: center;
      padding-top: 40px;
      padding-bottom: 40px;
      background-color: #201E1ECD;
    }

    & #marquee-slider-loop {
      width: 100%;
    }

    & img {
      height: 70px;
      object-fit: contain;
    }

    & .img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      aspect-ratio: 1/1;
      width: 160px;
    }


  }

}

/* Home about*/
.home-about {
  background-color: var(--primary);
  padding: 70px 0;

  & .home-about_top {
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: space-between;
    margin-bottom: 40px;

    & h2 {
      color: var(--primary-inverse)
    }

    & p {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      max-width: 340px;
      color: var(--primary-inverse)
    }
  }

  & .home-about_bottom {
    display: grid;
    grid-template-columns: 263fr 116fr 116fr 116fr;
    grid-template-rows: 1fr 1fr;
    gap: 24px;

    & .left {
      grid-row: span 2;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
      overflow: hidden;
      aspect-ratio: 1/1; //delete
      border-radius: 12px;
    }

    & .about-card {
      background-color: var(--secondary-dark);
      border-radius: 12px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: space-between;
      color: var(--primary-inverse);

      & div {
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -0.02em;
        text-align: left;

      }

      & p {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
      }
    }

    & video,
    & img {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      object-fit: contain;
    }


  }

}

@media (max-width: 1100px) {
  .home-about {
    padding: 40px 0;

    & .home-about_top {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      & p {
        font-size: 16px;
        line-height: 20px;
        max-width: unset;
      }
    }

    & .home-about_bottom {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: unset;

      & .my-btn {
        grid-column: span 2;
        width: 100%;
        text-align: center;
      }

      & .left {
        grid-column: span 2;
        grid-row: span 1;
        aspect-ratio: auto;
      }

      & .about-card {
        aspect-ratio: 1/1;
        padding: 16px;

        & div {
          font-size: 24px;
          line-height: 32px;
        }

        & p {
          font-size: 12px;
          line-height: 16px;
        }
      }

      & video {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        object-fit: contain;
      }


    }

  }
}

/* Home news*/
.home-news {

  &>.my-btn.pc {
    margin-bottom: 40px;
  }

  & .news {
    display: grid;
    align-items: stretch;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;

  }
}

@media (max-width: 1100px) {
  .home-news {
    & .news {
      grid-template-columns: 1fr;

      & .my-btn {
        width: 100%;
        text-align: center;
      }
    }
  }
}

/* Home faq*/
.home-faq {
  & .w-full.flex.flex-col .w-full.flex.flex-col {
    padding: 24px;
    border-radius: 12px;
    background-color: var(--primary-inverse);

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    & span {
      flex-shrink: 0;
    }

  }

  & .accordeon-button {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
  }

  & .accordeon-button+div>div {
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    opacity: 0.7;
    padding-top: 16px;
  }

  & .my-btn {
    margin: 40px auto 0;
    display: block;
  }
}

@media (max-width: 1100px) {
  .home-faq {
    & .accordeon-button {
      font-size: 16px;
      line-height: 20px;

    }

    & .accordeon-button+div>div {
      font-size: 16px;
      line-height: 20px;
    }

    & .my-btn {
      margin: 40px auto 0;
      display: block;
    }
  }
}

/* Home subscription*/
.home-subscription {
  padding: 70px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--primary);
  color: var(--primary-inverse);

  & h2 {
    text-align: center;
    margin-bottom: 12px;
  }

  & p {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-bottom: 40px;
  }

  & .wrapper {
    width: 100%;
    position: relative;
    border-radius: 40px;
    background-color: var(--secondary-dark);
    padding: 20px;
    border: 1px solid transparent;

    & .my-btn {
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
    }

    & input {
      background-color: transparent;
      outline: none;
    }
  }
}

@media (max-width: 1100px) {
  .home-subscription {
    padding: 70px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--primary);
    color: var(--primary-inverse);

    & h2 {
      text-align: center;
      margin-bottom: 12px;
    }

    & p {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      margin-bottom: 40px;
    }


  }
}

/* Home map*/
.home-map {
  position: relative;

  & .content {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 500px;
  }
}

/*------*/
/* PAGES*/
/*------*/
/* new */
.brand-list {
  & .brand-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    align-items: s;

    & .brand {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      padding: 24px;
      border-radius: 12px;
      border: 1px solid var(--disabled);

      &:hover {
        box-shadow: 0px 0px 3px var(--accent);

        & img {
          transform: scale(1.1);
        }

      }
    }

    & .img-wrapper {
      aspect-ratio: 3/2;
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      & img {
        width: 100%;
        object-fit: contain;
        transition: all 0.2s linear;
      }
    }


  }

  & .brand {
    text-align: center;
  }
}

@media (max-width: 1100px) {
  .brand-list {
    & .brand-wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

/* about */
.about-page {
  & .row {
    display: grid;
    grid-template-columns: 4.5fr 5fr;
    gap: 5%;
    margin-bottom: 40px;

    & .description {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      text-align: left;

    }
  }

  & .banner {
    width: 100%;
    border-radius: 12px;
    object-fit: contain;
    object-position: center bottom;
    margin-bottom: 160px;
  }

  & video {
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 160px;
  }

  & .history-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 160px;

    &>div:first-child {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 120px;
      padding-right: 30px;
      padding-top: 30px;
      border-right: 2px solid var(--accent);
    }

    .entry {
      background-color: var(--primary-inverse);
      border-radius: 12px;
      padding: 32px;
      overflow: visible;
      position: relative;

      &:nth-child(even) {
        position: absolute;
        width: anchor-size(width);
        left: calc(anchor(right) + 60px);
        top: calc(anchor(top) + 250px);

        &::after {
          position: absolute;
          content: '';
          border-radius: 100%;
          background-color: var(--accent);
          width: 30px;
          height: 30px;
          top: -30px;
          left: -44px;
        }

        &::before {
          position: absolute;
          content: '';
          background-color: var(--primary-inverse);
          width: 40px;
          height: 40px;
          top: 50px;
          left: -15px;
          transform: rotateZ(45deg);
        }
      }

      &:nth-child(odd) {
        &::after {
          position: absolute;
          content: '';
          border-radius: 100%;
          background-color: var(--accent);
          width: 30px;
          height: 30px;
          top: -30px;
          right: -45px;
        }

        &::before {
          position: absolute;
          content: '';
          background-color: var(--primary-inverse);
          width: 40px;
          height: 40px;
          top: 50px;
          right: -15px;
          transform: rotateZ(45deg);
        }
      }

      & .title {
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -0.02em;
        margin-bottom: 16px;
      }

      & .description {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        margin-bottom: 24px;
      }

      & img {
        width: 100%;
        aspect-ratio: 1.8/1;
        border-radius: 12px;
        object-fit: cover;
      }
    }
  }

  & .philosophy-banner {
    width: 100%;
    border-radius: 12px;
    margin-bottom: 160px;
  }

  & .advantages {

    & .overlay {
      padding-top: 65px;
      padding-bottom: 75px;
      background-color: #000000CD;
    }

    & .header {
      color: var(--primary-inverse)
    }
  }

  & .advantage-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;

    & .advantage-entry {
      align-self: stretch;
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-direction: column;
      border-radius: 12px;
      background-color: #ffffff1f;
      padding: 32px;

      & .title {
        color: var(--primary-inverse);
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -0.02em;
        text-align: left;
        margin-bottom: 10px;
      }

      & .description {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: var(--primary-inverse);
      }
    }
  }
}

@media (max-width: 1100px) {
  .about-page {
    & .row {
      grid-template-columns: 1fr;
      gap: 0;

      & .description {
        font-size: 16px;
        line-height: 20px;
      }
    }

    & .banner {
      margin-bottom: 80px;
    }

    & .history-2 {
      grid-template-columns: 1fr;
      margin-bottom: 80px;
      position: relative;
      overflow: visible;

      &::after {
        position: absolute;
        content: '';
        border-radius: 100%;
        background-color: var(--accent);
        width: 30px;
        height: 30px;
        top: -15px;
        left: -14px;
      }

      & .helper {
        display: none;
      }

      &>div:first-child {
        gap: 20px;
        padding-right: 0;
        padding-left: 40px;
        border-left: 2px solid var(--accent);
        border-right: none;
      }

      .entry {
        padding: 24px;

        &:nth-child(even) {
          position: relative;
          width: anchor-size(width);
          left: calc(anchor(right) + 80px);
          top: calc(anchor(top) + 200px);

          &::after {
            display: none;
          }
        }

        &:nth-child(odd) {
          &::after {
            display: none;
          }

          &::before {
            position: absolute;
            content: '';
            background-color: var(--primary-inverse);
            width: 40px;
            height: 40px;
            top: 50px;
            left: -15px;
            right: unset;
            transform: rotateZ(45deg);
          }
        }

        & .title {
          font-size: 24px;
          line-height: 32px;

        }

        & .description {
          font-size: 12px;
          line-height: 16px;
        }

        & img {
          width: 100%;
          aspect-ratio: 5/4;
          border-radius: 12px;
        }
      }
    }

    & .philosophy-banner {

      margin-bottom: 80px;
    }

    & .advantages {

      & .overlay {
        padding-top: 40px;
        padding-bottom: 40px;
      }

    }

    & .advantage-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;

      & .advantage-entry {
        padding: 16px;

        & .title {
          font-size: 16px;
          line-height: 20px;
        }

        & .description {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

/* series */
.series-page {
  & .series-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  .entry {
    aspect-ratio: 1296 / 510;
    border-radius: 12px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding-bottom: 20px;
    position: relative;
    overflow: hidden;

    & .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      z-index: -1;
      transition: all 0.2s linear;
    }

    & span {
      padding: 16px;
      background-color: var(--primary-inverse);
      border-radius: 12px;
      transition: all 0.2s linear;
    }
  }

  .entry:hover {
    & .overlay {
      filter: brightness(0.5);
    }

    & span {
      background-color: var(--accent);
      color: var(--primary-inverse);
      border-radius: 12px;
    }
  }
}

@media (max-width: 1100px) {
  .series-page {
    & .series-list {
      grid-template-columns: 1fr;
    }
  }
}

/* Vacancy */
.vacancy-page {
  & .row {
    display: grid;
    grid-template-columns: 4.5fr 5fr;
    gap: 5%;
    margin-bottom: 40px;
  }

  & img {
    width: 100%;
    aspect-ratio: 2.6/1;
    border-radius: 12px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 160px;
  }

  & video {
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 40px;
  }

  & .description {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    margin-bottom: 40px;
  }

  & .disclaimer {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  & .vacancy-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    align-self: start;

    & .my-btn {
      width: 100%;
    }

  }
}

@media (max-width: 1100px) {
  .vacancy-page {
    & .row {
      grid-template-columns: 1fr;
      margin-bottom: 40px;
    }

    & img {
      aspect-ratio: 1/1;
      margin-bottom: 80px;
    }

    & .description {
      font-size: 16px;
      line-height: 20px;
    }

    & .disclaimer {
      font-size: 16px;
      line-height: 20px;
    }

    & .vacancy-form {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-self: start;
    }
  }
}

/* Cooperation */
.cooperation-page {
  & .row {
    display: grid;
    grid-template-columns: 4.5fr 5fr;
    gap: 5%;
    margin-bottom: 40px;
  }

  & img {
    width: 100%;
    aspect-ratio: 2.6/1;
    border-radius: 12px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 160px;
  }

  & video {
    border-radius: 12px;
    overflow: hidden;
  }

  & .description {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    margin-bottom: 40px;
  }

  & .disclaimer {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  & .cooperation-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-self: start;
    gap: 24px;
  }
}

@media (max-width:1100px) {
  .cooperation-page {
    & .row {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0;
      margin-bottom: 40px;
    }

    & img {
      width: 100%;
      aspect-ratio: 1/1;

      margin-bottom: 80px;
    }

    & .description {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 0;
    }

    & .disclaimer {
      margin-bottom: 20px;
    }

    & .cooperation-form {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      align-self: start;
      gap: 24px;
    }
  }
}

/* HOME */
.home-page {
  & .hero {
    width: 100%;
    height: 100vh;
    position: relative;
    margin-bottom: 160px;
    padding: 70px;

    display: flex;
    align-items: start;
    flex-direction: column-reverse;

    & .hero-text {
      color: white;
    }

    & .slogan {
      font-size: 64px;
      font-weight: 700;
      line-height: 64px;
      letter-spacing: -0.02em;
      margin-bottom: 24px;
    }

    & .description {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      max-width: 548px;
    }
  }

  .section {
    margin-bottom: 160px;
  }
}

@media (max-width: 1100px) {
  .home-page {
    & .hero {
      margin-bottom: 80px;
      padding: 40px 20px;

      & .slogan {
        font-size: 36px;
        line-height: 40px;
      }

      & .description {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .section {
      margin-bottom: 80px;
    }
  }
}

/* news page */
.news-page {

  & .chip-select {
    margin-bottom: 40px;
  }

  & .news-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    min-height: 450px;
  }
}

@media (max-width: 1100px) {
  .news-page {
    & .chip-select {
      margin-bottom: 40px;
      margin-right: -20px;
    }

    & .news-list {
      display: flex;
      flex-direction: column;
      min-height: 500px;
    }

  }
}

/* news detail page */
.news-detail-page {
  & .content {
    &>img {
      border-radius: 12px;
      aspect-ratio: 1.8/1;
      object-position: center;
      object-fit: cover;
      background-color: var(--accent);
      margin-bottom: 40px;
    }

    & .section-header {
      font-size: 32px;
      font-weight: 500;
      line-height: 40px;
      letter-spacing: -0.02em;
      margin-bottom: 24px;
    }

    & p {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 40px;
      color: var(--disabled-text)
    }
  }

  .wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10%;
    margin-bottom: 160px;
  }

  & .headings {
    position: sticky;
    top: 150px;
    align-self: start;

    h2 {
      margin-bottom: 16px;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
    }

    & li {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: var(--disabled-text);

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  & .recommendations .list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
  }

}

@media (max-width: 1100px) {
  .news-detail-page {
    & .content {
      & .section-header {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px;
      }

      & p {
        font-size: 16px;
        line-height: 20px;


      }
    }

    .wrapper {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      margin-bottom: 80px;
    }

    & .headings {
      display: none;
    }

    & .headings-inner {
      margin-bottom: 40px;

      h2 {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 16px;

      }

      & li {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: var(--disabled-text);

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }

    & .recommendations .list {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 24px;
    }

  }
}

/* product catalog page */
.catalog-page {
  & .category-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    row-gap: 40px;
    align-items: stretch;
  }

  .category {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    & .img-wrapper {
      width: 100%;
      margin-bottom: 16px;
      position: relative;
      overflow: hidden;
      border-radius: 12px;

    }

    & .overlay {
      position: absolute;
      inset: 0;
      transition: all 0.2s linear;

    }

    &:hover .overlay {
      backdrop-filter: brightness(50%);
    }

    & img {
      width: 100%;
      aspect-ratio: 1.6/1;
      border-radius: 12px;
      object-fit: cover;
    }

    & .name {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
    }

    &:hover .name {
      color: var(--accent)
    }
  }
}

@media (max-width: 1100px) {
  .catalog-page {
    & .category-list {
      grid-template-columns: 1fr;
    }

    .category {
      & .name {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
      }
    }
  }
}

/* product category page */
.category-page {
  & .category-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;
    row-gap: 40px;
    align-items: stretch;
  }

  .category {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    & .img-wrp {
      width: 100%;
      aspect-ratio: 5/6;
      border-radius: 12px;
      padding: 24px;
      background-color: var(--secondary);
      margin-bottom: 16px;
      overflow: hidden;
      transition: all 0.2s linear;
    }



    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: all 0.2s linear;
    }

    & .name {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
    }

    &:hover {
      & .img-wrp {

        background-color: var(--disabled);
      }

      & img {
        transform: scale(1.1);
      }

      & .name {
        color: var(--accent)
      }
    }
  }
}

@media (max-width: 1100px) {
  .category-page {
    & .category-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      row-gap: 40px;
      align-items: stretch;
    }
  }
}

/* Contacts */
.contacts-page {
  display: grid;
  grid-template-columns: 1fr 1fr;

  & .header {
    margin-bottom: 40px;
  }

  & .info {
    width: 70%;
    margin-bottom: 24px;

    & .label {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      opacity: 0.7;
      margin-bottom: 8px;
    }

    & .value {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
    }
  }

  & .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    & textarea {
      grid-column: span 2;
    }

    & div {
      grid-column: span 2;
      padding-top: 20px;
      width: 100%;

      & .my-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

      }
    }
  }

  & .socials {
    display: flex;
    gap: 8px;
    align-items: center;

    & a {
      display: block;
      padding: 16px;
      background-color: var(--primary-inverse);
      border-radius: 100%;

    }

    & img {
      width: 24px;
      height: 24px;
      object-fit: contain;
      display: block;
    }
  }
}

@media (max-width: 1100px) {
  .contacts-page {
    grid-template-columns: 1fr;
    gap: 80px;

    & .info {
      width: unset;

      & .label {
        font-size: 16px;
        line-height: 20px;
        opacity: 0.7;
        margin-bottom: 8px;
      }

      & .value {
        font-size: 16px;
        line-height: 20px;
      }
    }

    & .form {
      display: flex;
      flex-direction: column;
      width: 100%;

      gap: 20px;

      & div {
        padding-top: unset;
      }
    }
  }
}

/* Product page */
.product-page {
  & .top {
    display: flex;
    gap: 24px;
    margin-bottom: 80px;

    & .header {
      margin-bottom: 16px;
    }



    & .all-specs-link {
      border-bottom: 1px solid var(--primary);
      cursor: pointer;

      width: fit-content;

      &:hover {
        border-color: var(--accent);
        color: var(--accent-hover)
      }
    }
  }

  & .info {
    width: calc(50% - 24px)
  }

  & .image-wrapper {
    width: 50%;
    aspect-ratio: 1/1;
    background-color: var(--secondary);
    border-radius: 12px;
    padding: 50px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  & .short-description {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    opacity: .7;
    margin-bottom: 40px;

    &+.typo-body-m {
      margin-bottom: 24px;
    }
  }

  & .spec-row {
    display: grid;
    grid-template-columns: 1fr 250px;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;

    & .left {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 16px;

      & .dashes {
        border-top: 1px dashed var(--primary);
        opacity: 20%;
        flex-grow: 1;
      }
    }
  }

  & .star-row {
    display: flex;
    align-items: baseline;
    gap: 8px;
    margin-bottom: 40px;

    &>span:last-child {
      opacity: .4;
    }

    & * {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    & .star-container {
      display: flex;
      align-items: baseline;
      gap: 2px;
    }
  }

  & .composition {
    &>div:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  & .red-bg {
    background-color: var(--accent);
  }

  & .active {
    color: var(--primary-inverse);
    background-color: transparent;
  }

  & .inactive {
    color: var(--primary);
    background-color: var(--primary-inverse);
  }

  & .product-container {
    padding-top: 40px;
    padding-bottom: 80px;
  }

  & .banner {
    aspect-ratio: 2/1;
    object-fit: contain;
    background-color: var(--secondary);
    margin-bottom: 160px;

  }

  & .review-tab {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2.3fr .7fr;

    gap: 24px;

    & .review-info {
      & .title {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        text-align: left;
        margin-bottom: 24px;
      }

      & .review-row {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 5px;

        &:not(:has(+&)) {
          margin-top: 20px;
        }

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        & .text {
          opacity: 0.4;
        }

        & .progress {
          flex-grow: 1;
          height: 4px;
          border-radius: 10px;
          background-color: var(--primary-inverse);
          position: relative;

          & .inner {
            position: absolute;
            left: 0;
            top: 0;
            height: 4px;
            border-radius: 10px;
            background-color: #E7B73F;
          }
        }

        ;
      }
    }

    & .review-list {
      width: 100%;

      &>div {
        &:not(:last-child) {
          margin-bottom: 24px;
        }

        border-radius: 12px;
        padding: 32px;
        background-color: var(--primary-inverse);
      }

      & .top-line {
        display: flex;
        width: 100%;
        align-items: start;
        justify-content: space-between;
        margin-bottom: 24px;
      }


    }

    & .btn-container {
      justify-self: end;
    }
  }

  & .recommend {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
  }

}

@media (max-width: 1100px) {
  .product-page {
    & .top {
      display: block;
    }

    & .info {
      width: 100%;
    }

    & .image-wrapper {
      padding: 32px;
      width: 100%;
      margin-bottom: 24px;
    }

    & .composition {
      &>div:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    & .accordeon {
      margin-bottom: 80px;

      &>div {
        padding: 24px;
        background-color: var(--primary-inverse);
        border-radius: 12px;

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }

      &>div>button {
        color: var(--primary) !important;
      }

      &>div>button {
        background-color: transparent;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.02em;
        text-align: left;
        padding: 0;
        margin-bottom: 0;

      }

      & .spec-row {
        display: grid;
        grid-template-columns: 1fr auto;
        width: 100%;
        align-items: start;
        gap: 16px;
        margin-bottom: 16px;

        .typo-body-m {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          text-align: left;

        }

        & .left {
          display: flex;
          width: 100%;
          align-items: center;
          gap: 16px;

          & .dashes {
            border-top: 1px dashed var(--primary);
            opacity: 20%;
            flex-grow: 1;
            min-width: 40px;
          }
        }
      }
    }

    & .composition {
      &>div:not(:last-child) {
        margin-bottom: 20px;

      }

      & .typo-body-m {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
      }
    }

    & .review-accordeon {
      & .title {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        margin-bottom: 24px;

      }

      & .review-row {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 5px;

        &:not(:has(+&)) {
          margin-top: 20px;
        }

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        &:last-child {
          margin-bottom: 32px;
        }

        & .text {
          opacity: 0.4;
        }

        & .progress {
          flex-grow: 1;
          height: 4px;
          border-radius: 10px;
          background-color: var(--tertiary);
          position: relative;

          & .inner {
            position: absolute;
            left: 0;
            top: 0;
            height: 4px;
            border-radius: 10px;
            background-color: #E7B73F;
          }
        }

        ;
      }

      & .review-list {
        width: 100%;

        &>div {
          &:not(:last-child) {
            margin-bottom: 24px;
          }

          border-radius: 12px;
          padding: 16px;
          background-color: var(--tertiary);
        }

        & .top-line {
          display: flex;
          width: 100%;
          align-items: start;
          justify-content: space-between;
          margin-bottom: 24px;

          & .entry-info {
            display: flex;
            flex-wrap: nowrap;
            gap: 2px
          }
        }


      }

    }

    & .btn-container .my-btn {
      width: 100%;
    }

    & .banner {
      margin-bottom: 80px;
    }

    & .recommend {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>
